import { useNavigate } from 'react-router';

interface Props {}

const NotFound: React.FC<Props> = ({}: Props) => {
  const navigate = useNavigate();

  return (
    <div className="w-screen flex items-center py-24">
      <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-800">
        <div className="max-w-md">
          <div className="text-5xl font-dark font-bold">404</div>
          <p className="text-2xl md:text-3xl font-light leading-normal">Sorry we couldn&apos;t find this page. </p>
          <p className="mb-8">But don&apos;t worry, you can find plenty of other things on our homepage.</p>

          <button
            className="px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue bg-blue-600 active:bg-blue-600 hover:bg-blue-700"
            onClick={() => navigate('/')}>
            back to homepage
          </button>
        </div>
        <div className="max-w-lg">
          <img src="/404.png" />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
